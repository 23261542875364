import IFormContainer from './interfaces/IFormContainer';
import styles from './sass/FormContainer.module.scss';

const FormContainer = ({ children, onSubmit }: IFormContainer) => (
  <form onSubmit={onSubmit} className={styles.container}>
    {children}
  </form>
);

export default FormContainer;
