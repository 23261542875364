import Input from '../Input/Input';
import InputPassword from '../InputPassword/InputPassword';
import TextField from '../TextField/TextField';
import InterfacesInputWithLabel from './interfaces/InterfacesInputWithLabel';
import styles from './sass/InputWithLabel.module.scss';
import PhoneInput from '../PhoneInput/PhoneInput';
import DatePicker from '../DatePicker/DatePicker';

const InputWithLabel = ({
  styleType,
  label,
  value,
  type,
  id,
  placeholder,
  handlerChange,
  handlerDateChange,
  styleInput,
  styleContainer,
  disabled,
  titleDesc,
  styleTypeDesc,
  description,
  typeDesc,
  onChangeInputPhone,
  error,
  passwordInput = false,
  phoneInput = false,
  datepickerInput = false,
}: InterfacesInputWithLabel) => (
  <div className={`${styles.container} ${styleContainer ? styles[styleContainer] : ''}`}>
    <div className={styles.titleContainer}>
      <TextField
        text={label}
        styleType={styleType}
      />
      {titleDesc && (
        <TextField
          text={titleDesc}
          styleType={styleTypeDesc}
        />
      )}
    </div>
    {description && (
      <TextField
        text={description}
        styleType={typeDesc}
      />
    )}
    {(passwordInput && !phoneInput) && (
      <InputPassword
        id={id}
        value={value}
        placeholder={placeholder}
        handlerChange={handlerChange}
      />
    )}
    {(!passwordInput && !phoneInput && !datepickerInput) && (
      <Input
        id={id}
        type={type}
        value={value}
        placeholder={placeholder}
        handlerChange={handlerChange}
        styleType={styleInput}
        disabled={disabled}
        error={error}
      />
    )}
    {datepickerInput && (
      <DatePicker handlerChange={handlerDateChange} />
    )}
    {phoneInput && (
      <PhoneInput
        id={id || ''}
        value={value}
        onChangeInput={onChangeInputPhone}
      />
    )}

  </div>
);

export default InputWithLabel;
