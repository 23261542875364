import { useState } from 'react';
import Image from 'next/image';
import Button from '../Button/Button';
import InterfacesInput from './interface/IInputPassword';
import styles from './sass/InputPassword.module.scss';

const InputPassword = ({
  id, placeholder, value, handlerChange,
}: InterfacesInput) => {
  const [show, setShow] = useState(true);

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <div className={styles.container}>
      <input
        id={id}
        type={show ? 'password' : 'text'}
        value={value}
        placeholder={placeholder}
        onChange={handlerChange}
        autoComplete="off"
      />
      <Button handleClick={handleClick} styleButton="inputPasswordBtn">
        <Image src={show ? '/eye.svg' : '/eyeBlue.svg'} alt="logo" width={30} height={23} />
      </Button>
    </div>
  );
};

export default InputPassword;
