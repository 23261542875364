import { forwardRef, LegacyRef } from 'react';
import { useTranslation } from 'next-i18next';
import Input from '../../Input/Input';
import IDatePickerInput from '../interfaces/IDatePickerInput';
import CalendarIcon from '../../../assets/course/dateRange.svg';

const DatePickerInput = forwardRef(({ value, onClick }: IDatePickerInput, ref) => {
  const { t } = useTranslation('common');

  return (
    <div ref={ref as LegacyRef<HTMLDivElement>}>
      <Input
        onClick={onClick}
        handlerChange={() => {}}
        value={value}
        icon={<CalendarIcon />}
        placeholder={t('datePickerFormat')}
      />
    </div>
  );
});

export default DatePickerInput;
