import React from 'react';
import ErrorIcon from '../../assets/common/clarity_error-line.svg';
import TextField from '../TextField/TextField';
import IFormError from './interfaces/IFormError';
import styles from './sass/FormError.module.scss';

const FormError = ({ error }: IFormError) => (
  <div className={styles.formError}>
    <ErrorIcon />
    <TextField text={error} styleType="formErrorMessage" />
  </div>
);

export default FormError;
