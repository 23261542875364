export const FORM_TEXT = {
  EMAIL: 'email',
  PASSWORD: 'password',
  CONFIRM: 'confirm',
};

export const URL_REDIRECT = {
  TO_FORGOT_PASSWORD: '/account/reset',
  AS_FORGOT_PASSWORD: '/account/reset',
  TO_LOGIN: '/login',
};

export const STYLES_FORM = {
  INPUT_LABEL: 'inputLabel',
  FORGOT_PASSWORD: 'forgotPassword',
  TITLE_FORM_BIG: 'titleFormBig',
  TITLE_FORM: 'titleForm',
  DESCRIPTION: 'description',
  TIMER_LABEL: 'timerLabel',
  RECEIVE_EMAIL: 'receiveEmail',
  DESCRIPTION_PASS: 'descriptionPass',
  TIMER: 'timer',
  TITLE_SUCCESS: 'titleSuccess',
  DESCRIPTION_SUCCESS: 'descriptionSuccess',
  SUCCESS_BUTTON: 'successButton',
  RETURN: 'returnToLogin',
  RETURN_SUCCESS: 'returnToLoginSuccess',
  INPUT_ERROR: 'inputError',
};
