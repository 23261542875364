import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Cookies from 'js-cookie';
import LoginView from '../src/pages/Login/LoginView';
import IStaticProps from '../src/interfaces/IStaticProps';
import { login } from '../src/store/auth/slice';
import { getUserInfo } from '../src/store/user/slice';
import { TOKEN_HELPER } from '../src/constants/helperConstants';
import { useAppDispatch } from '../src/hooks/storeHook';

const Login = () => {
  const { t } = useTranslation('login');
  const dispatch = useAppDispatch();
  const history = useRouter();
  const [inputsState, setInputsState] = useState({
    email: '',
    password: '',
  });

  const [formError, setError] = useState('');

  useEffect(() => {
    Cookies.remove(TOKEN_HELPER.ACCESS);
    Cookies.remove(TOKEN_HELPER.REFRESH);
    Cookies.remove(TOKEN_HELPER.ROLE);
  }, []);

  const handlerInputs = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;
    setInputsState({ ...inputsState, [id]: value });
  };

  const submitLogin = (event: React.SyntheticEvent<EventTarget>) => {
    event.preventDefault();
    dispatch(login({ ...inputsState, setError, history }))
      .then(() => dispatch(getUserInfo()))
      .catch(() => {});
    const accessToken = Cookies.get('accessToken') || '';
    if (!accessToken) {
      setTimeout(() => setError('Unauthorized'), 2500);
    }
  };

  return (
    <LoginView
      handlerInputs={handlerInputs}
      submitLogin={submitLogin}
      inputsState={inputsState}
      inputEmail={t('emailAddress')}
      placeholderEmail={t('enterEmail')}
      inputPassword={t('password')}
      placeholderPassword={t('enterPassword')}
      labelButton={t('logIn')}
      linkLabel={t('forgotPasswordLink')}
      formError={formError}
    />
  );
};

export const getStaticProps = async ({ locale }: IStaticProps) => ({
  props: {
    ...(await serverSideTranslations(locale, ['common', 'login'])),
  },
});

export default Login;
