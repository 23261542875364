import InputPhone from 'react-phone-input-2';
import IPhoneInput from './interfaces/IPhoneInput';
import styles from './sass/PhoneInput.module.scss';

const PhoneInput = ({ value, onChangeInput, id }:IPhoneInput) => {
  const handlerInput = (data: string, idData: string) => {
    if (onChangeInput) {
      onChangeInput(data, idData);
    }
  };

  return (
    <div className={styles.container}>
      <InputPhone
        country="us"
        onChange={(data) => handlerInput(data, id)}
        inputClass={styles.inputClass}
        buttonClass={styles.buttonClass}
        value={value}
      />
    </div>
  );
};

export default PhoneInput;
