import { useTranslation } from 'next-i18next';
import InterfacesInput from './interfaces/InterfacesInput';
import styles from './sass/Input.module.scss';

const Input = ({
  type = 'text',
  id,
  placeholder,
  value,
  handlerChange,
  onClick,
  styleType,
  disabled,
  error,
  icon,
}: InterfacesInput) => {
  const { t } = useTranslation('common');
  const valueInput = value || '';

  return (
    <div className={`${styles.container} ${styleType ? styles[styleType] : ''} ${error ? styles.errorWrapper : ''}`}>
      <input
        id={id}
        type={type}
        value={valueInput}
        placeholder={placeholder}
        onChange={handlerChange}
        onClick={onClick}
        autoComplete="off"
        disabled={disabled}
      />
      { icon && <div className={styles.icon}>{icon}</div>}
      { error && <span className={styles.error}>{t(error)}</span>}
    </div>
  );
};

export default Input;
