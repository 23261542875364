import { createElement, useState } from 'react';
import DatePicker from 'react-datepicker';
import DatePickerInput from './components/DatePickerInput';
import IDatePicker from './interfaces/IDatePicker';

const DatePickerCustom = ({ handlerChange = () => {} }: IDatePicker) => {
  const [startDate, setStartDate] = useState<Date | null>(null);

  const onDateChange = (date: Date | null) => {
    if (date) {
      handlerChange(date);
    }
    setStartDate(date);
  };

  return (
    <DatePicker
      showPopperArrow={false}
      customInput={createElement(DatePickerInput)}
      selected={startDate}
      onChange={onDateChange}
      dateFormat="dd/MM/yyyy"
    />
  );
};

export default DatePickerCustom;
