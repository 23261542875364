import ILogin from './interfaces/ILogin';
import Button from '../../components/Button/Button';
import FormContainer from '../../components/FormContainer/FormContainer';
import InputWithLabel from '../../components/InputWIthLabel/InputWithLabel';
import LinkItem from '../../components/Link/Link';
import LogoForm from '../../components/LogoForm/LogoForm';
import Layout from '../../layout/Layout';
import styles from './sass/Login.module.scss';
import FormError from '../../components/FormError/FormError';
import { FORM_TEXT, URL_REDIRECT, STYLES_FORM } from '../../constants/formConstants';

const LoginView = ({
  handlerInputs,
  submitLogin,
  inputsState,
  inputEmail,
  placeholderEmail,
  inputPassword,
  placeholderPassword,
  labelButton,
  linkLabel,
  formError,
}:ILogin) => (
  <Layout>
    <div className={styles.loginWrapper}>
      <FormContainer onSubmit={submitLogin}>
        <LogoForm />
        <div className={styles.containerInputs}>
          <InputWithLabel
            id={FORM_TEXT.EMAIL}
            type={FORM_TEXT.EMAIL}
            styleType={STYLES_FORM.INPUT_LABEL}
            label={inputEmail}
            value={inputsState.email}
            handlerChange={handlerInputs}
            placeholder={placeholderEmail}
            phoneInput={false}
            styleInput={formError ? STYLES_FORM.INPUT_ERROR : ''}
          />
          {formError && <FormError error={formError} />}
          <InputWithLabel
            id={FORM_TEXT.PASSWORD}
            type={FORM_TEXT.PASSWORD}
            styleType={STYLES_FORM.INPUT_LABEL}
            label={inputPassword}
            value={inputsState.password}
            handlerChange={handlerInputs}
            placeholder={placeholderPassword}
            phoneInput={false}
          />
        </div>
        <LinkItem
          url={URL_REDIRECT.TO_FORGOT_PASSWORD}
          as={URL_REDIRECT.AS_FORGOT_PASSWORD}
          styleType={STYLES_FORM.FORGOT_PASSWORD}
          text={linkLabel}
        />
        <Button
          handleClick={submitLogin}
          label={labelButton}
          submit
        />
      </FormContainer>
    </div>
  </Layout>
);

export default LoginView;
